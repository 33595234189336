export const mockCharacteristics = [
  { id: '123', label: 'Возраст', value: 21 },
  { id: '124', label: 'Рост', value: 180 },
  { id: '125', label: 'Вес', value: 75 },
  { id: '126', label: 'Цвет глаз', value: 'Голубой' },
  { id: '127', label: 'Цвет волос', value: 'Черный' },
  { id: '128', label: 'Пол', value: 'Мужской' },
  { id: '129', label: 'Национальность', value: 'Русский' },
  { id: '130', label: 'Профессия', value: 'Инженер' },
  { id: '131', label: 'Хобби', value: 'Чтение' },
  { id: '132', label: 'Любимый цвет', value: 'Синий' }
];
